export const supplierMenu: any = [
  {
    id: "M1",
    name: "Onboarding",
    iconName: "onboarding",
    url: "/onboarding",
    subMenu: [
      {
        name: "Overview",
        url: "onboarding/overview",
      },
    ],
  },

  {
    id: "M5",
    name: "Maintenance",
    iconName: "maintenance",
    url: "/maintenance",
    subMenu: [
      {
        name: "Maintenance",
        url: "maintenance/maintenance",
      },
      {
        name: "Vehicle Defect’s",
        url: "/maintenance/vehicle-defects",
      },

      {
        name: "Breakdown's",
        url: "/maintenance/breakdowns",
      },
    ],
  },
  {
    id: "M6",
    name: "FileExport",
    iconName: "file-export",
    url: "/file-export",
  },
];
