import React, { Suspense, lazy, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import Header from "./header/Header";
import Aside from "./Aside/Aside";
import Error from "./Error/Error";
import Login from "../../Login";

// Lazy-load components from shared packages
const RoutingOnboardingBjs = lazy(() =>
  import("@bjsdistribution/tms-onboarding").then((module) => ({
    default: module.RoutingOnboardingBjs,
  }))
);

const Report = lazy(() =>
  import("@bjsdistribution/tms-onboarding").then((module) => ({
    default: module.Report,
  }))
);

const AddVehicle = lazy(() =>
  import("@bjsdistribution/tms-add-vehicle").then((module) => ({
    default: module.AddVehicle,
  }))
);

const RoutingDefectsBjs = lazy(() =>
  import("@bjsdistribution/tms-vehicle-maintenance").then((module) => ({
    default: module.RoutingDefectsBjs,
  }))
);

const RoutingBreakdownBjs = lazy(() =>
  import("@bjsdistribution/tms-vehicle-maintenance").then((module) => ({
    default: module.RoutingBreakdownBjs,
  }))
);

const RoutingChecksBjs = lazy(() =>
  import("@bjsdistribution/tms-vehicle-maintenance").then((module) => ({
    default: module.RoutingChecksBjs,
  }))
);

const RoutingMaintenance = lazy(() =>
  import("@bjsdistribution/tms-vehicle-maintenance").then((module) => ({
    default: module.RoutingMaintenance,
  }))
);

const RoutingHr = lazy(() =>
  import("@bjsdistribution/tms-hr").then((module) => ({
    default: module.AddDriverComponent,
  }))
);

const FileExport = lazy(() =>
  import("@bjsdistribution/tms-export").then((module) => ({
    default: module.FileExport,
  }))
);
const RoutingFinanceBjs = lazy(() =>
  import("@bjsdistribution/tms-finance").then((module) => ({
    default: module.RoutingFinanceBjs,
  }))
);
const RoutingJobs = lazy(() =>
  import("@bjsdistribution/tms-jobs").then((module) => ({
    default: module.RoutingJobs,
  }))
);

const CustomerConfigComponent = lazy(() =>
  import("@bjsdistribution/tms-customer-config").then((module) => ({
    default: module.CustomerConfigComponent,
  }))
);

function AppRouter({ setTheme }: any) {
  const [isLogin, setIsLogin] = useState<boolean>(() => {
    return !!localStorage.getItem("userInfo");
  });

  // const [isExpanded, setIsExpanded] = useState(true);
  // const toggleSidebar = () => setIsExpanded(!isExpanded);

  // PrivateRoute Component
  const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    const location = useLocation();
    return isLogin ? children : <Navigate to="/login" state={{ from: location }} replace />;
  };

  // Protected Layout Component
  const ProtectedLayout: React.FC = () => (
    <div className="coverDms">
      <MainContainer>
        <Aside setTheme={setTheme} />
        <div className="dashboard">
          <Header />

          <div className="placeHolder">
            <Suspense fallback={<LoadingMessage />}>
              <Routes>
                <Route path="/" element={<Navigate to="/onboarding/overview" replace />} />
                <Route path="/onboarding" element={<Navigate to="/onboarding/overview" replace />} />
                <Route path="/onboarding/*" element={<RoutingOnboardingBjs />} />
                <Route path="/onboarding/report" element={<Report />} />
                <Route path="/add-vehicle/*" element={<AddVehicle />} />
                <Route path="/maintenance" element={<Navigate to="/maintenance/maintenance" replace />} />
                <Route path="/maintenance/*" element={<RoutingMaintenance />} />

                <Route path="/maintenance/vehicle-defects/*" element={<RoutingDefectsBjs />} />
                <Route path="/maintenance/vehicle-checks/*" element={<RoutingChecksBjs />} />
                <Route path="/maintenance/breakdowns/*" element={<RoutingBreakdownBjs />} />
                {/* TMS HR */}
                <Route path="/hr" element={<Navigate to="/hr/overview" replace />} />
                <Route path="/hr/*" element={<RoutingHr />} />
                <Route path="*" element={<Error />} />
                <Route path="/file-export" element={<FileExport />} />
                <Route path="/finance/*" element={<RoutingFinanceBjs />} />
                <Route path="/jobs/*" element={<RoutingJobs />} />
                <Route path="/customer-config/*" element={<CustomerConfigComponent />} />
              </Routes>
            </Suspense>
          </div>
        </div>
      </MainContainer>
    </div>
  );

  return (
    <Router>
      <Routes>
        {/* Login Route */}
        <Route path="/login" element={isLogin ? <Navigate to="/onboarding/overview" /> : <Login setIsLogin={setIsLogin} />} />
        {/* Protected Routes */}
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <ProtectedLayout />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

const MainContainer = styled.div`
  display: flex;
  .dashboard {
    flex: 1;
    background: #eaedf7;
    transition: all ease 0.5s;
    .placeHolder {
      padding: 25px 20px 10px 20px;
    }
  }
`;

const LoadingMessage: React.FC = () => (
  <div style={{ textAlign: "center", marginTop: "20px" }}>
    <div className="spinner" />
    <p>Loading, please wait...</p>
  </div>
);

export default AppRouter;
